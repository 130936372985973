/* eslint-disable @next/next/no-img-element */
import { twMerge } from "tailwind-merge";
import PfpFrame from "./assets/pfp-frame.svg";

export type ProfileImageSize = "xs" | "sm" | "md" | "lg";

const levelVariants = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
};

const containerVariants = {
  xs: "w-[38px] h-[38px]",
  sm: "w-[64px] h-[64px]",
  md: "w-[78px] h-[78px]",
  lg: "w-[206px] h-[206px]",
};

const UserProfileImage = ({
  size,
  imageUrl,
  level,
  className,
}: {
  size: ProfileImageSize;
  imageUrl: string | null | undefined;
  level?: number;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        `relative flex items-center justify-center ${containerVariants[size]}`,
        className
      )}
    >
      <PfpFrame className="absolute w-full h-full" />
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Profile Image"
          className={`absolute z-20 w-[85%] h-[85%]`}
        />
      )}
      {!imageUrl && (
        <div className="absolute z-20 w-[85%] h-[85%] bg-black flex items-center justify-center overflow-hidden rounded-full">
          <img
            src={"/nopfp.svg"}
            alt="Profile Image"
            className={`!h-[70%] !w-[70%]`}
          />
        </div>
      )}

      {!!level && (
        <div className={`absolute z-40 top-0 right-0 ${levelVariants[size]}`}>
          <LevelBadge level={level} size={size} />
        </div>
      )}
    </div>
  );
};

export default UserProfileImage;

const levelSizeVariants = {
  xs: "w-[15px] h-[15px] text-[8px] border-[1px]",
  sm: "w-5 h-5 text-sm border-[2px]",
  md: "w-[26px] h-[26px] text-base border-[3px]",
  lg: "w-[48px] h-[48px] text-lg border-[4px]",
};

const LevelBadge = ({
  size,
  level,
}: {
  size: ProfileImageSize;
  level: number;
}) => {
  return (
    <div
      className={`z-40 border-bhDark bg-brand rounded-full flex items-center justify-center text-black font-bold ${levelSizeVariants[size]}`}
    >
      <p>{level}</p>
    </div>
  );
};
