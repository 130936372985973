import { shortenAddress } from "sushi";
import UserProfileImage from "./user-profile-image";
import UserBadgeContainer from "./assets/user-badge-container.svg";

type Props = {
  username?: string | null;
  address?: string | null;
  profileImageUrl?: string | null;
  totalPoints: number;
};

export default function UserBadge({
  username,
  address,
  profileImageUrl,
  totalPoints,
}: Props) {
  const capitalizedUsername = username
    ? username.charAt(0).toUpperCase() + username.slice(1)
    : null;

  const userLevel = calculateLevel(totalPoints);

  return (
    <div className="w-[158px] mr-4 relative cursor-pointer flex h-[55px] rounded-md p-1">
      <UserBadgeContainer className="absolute" />
      <div className="flex justify-between relative items-center pl-4 w-full h-full">
        <div className="flex flex-col leading-none gap-px">
          <div className="text-white text-sm font-bold text-left">
            {capitalizedUsername || shortenAddress(address || "")}
          </div>
          <div className="flex mb-[2px] -mt-[2px]">
            <div className="text-gray-400 text-[7pt] font-bold">
              RANK {userLevel}
            </div>
            <div className="text-gray-600 text-[7pt] font-bold mx-1">
              &#8226;
            </div>
            <div className="text-gray-400 text-[7pt] font-bold">
              {totalPoints.toFixed(2)} PTS
            </div>
          </div>
          <Progress size="small" max={6} current={3} />
        </div>
        <div className="">
          <UserProfileImage
            level={userLevel}
            size="sm"
            imageUrl={profileImageUrl}
          />
        </div>
      </div>
    </div>
  );
}

const sizeVariants = {
  small: "w-[14px] h-[3.5px] mr-[2px]",
  medium: "w-[18px] h-[4px] mr-[2px]",
  large: "w-[36px] h-[8px] mr-[1px]",
};
export function Progress({
  max,
  current,
  size = "small",
}: {
  max: number;
  current: number;
  size?: "small" | "medium" | "large";
}) {
  return (
    <div className="flex w-full">
      {new Array(max).fill(0).map((_, i) => (
        <div
          key={i}
          className={`${sizeVariants[size]} ${
            i < current ? "bg-white" : "bg-gray-400"
          } rounded-[2px] skew-x-[-45deg]`}
        />
      ))}
    </div>
  );
}

const calculateLevel = (points: number) => {
  const levels = [
    0, 400, 1300, 2700, 4800, 7600, 11200, 15700, 21100, 27600, 35200, 43900,
    53700, 64700, 77000, 90600, 105600, 122000, 139800, 159100, 179900, 202300,
    226300, 252000, 279400, 308600, 339600, 372500, 407300, 444100, 482900,
    523800, 566800, 612000, 659400, 709100, 761100, 815500, 872300, 931600,
    993400, 1057800, 1124800, 1194500, 1266900, 1342100, 1420100, 1501000,
    1584800, 1671600,
  ];

  for (let i = 0; i < levels.length; i++) {
    if (points < levels[i]) {
      return i;
    }
  }
  return 50; // Max level
};
